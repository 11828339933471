import { IonItem, IonList, IonText, IonThumbnail } from '@ionic/react'
import { ImageLoader } from '../../components/ImageLoader'
import config from '../../settings/config'
import { NoRecords } from '../../components/NoRecords'
import { useHistory } from 'react-router'
import { RecipeSkeleton } from '../../components/skeletons/RecipeSkeleton'

const RecipesContent = ({ planData, planIsLoading }) => {
	const history = useHistory()

	const planRecipes = planData?.dietPlan?.reduce(
		(acc, day, dayI) => {
			day?.forEach((meal) => {
				meal.tables.parent
					.filter((foodOrRecipe) => foodOrRecipe.source_type === 'recipe')
					.forEach((recipe) => {
						if (!acc.uniqueRecipeIds.has(recipe.recipe_id)) {
							acc.uniqueRecipeIds.add(recipe.recipe_id)
							acc.recipes.push({
								...recipe,
								day: dayI,
								cycleId: meal.id,
							})
						}
					})
			})
			return acc
		},
		{ uniqueRecipeIds: new Set(), recipes: [] }
	).recipes

	planRecipes?.sort((a, b) => a.name.localeCompare(b.name))

	const foods = planData?.foods

	return (
		<div className="ion-padding">
			{planIsLoading ? (
				<IonList>
					{[...Array(8)].map((_, index) => (
						<RecipeSkeleton key={index} />
					))}
				</IonList>
			) : planRecipes?.length > 0 ? (
				<IonList>
					{planRecipes?.map((recipe, index) => {
						const plan = planData?.dietPlan
						// console.log('plan', plan)

						if (!recipe.image && plan[recipe.day]) {
							// console.log('recipe', recipe)
							const cycle = plan[recipe.day].find(
								(e) => e.id === recipe.cycleId
							)

							const primaryFoodId = cycle.tables.childs[recipe.recipe_id]?.find(
								(e) => e.is_primary === true
							)?.food_id

							const primaryFood = foods?.find(
								(food) => food.id === primaryFoodId
							)

							if (primaryFood) {
								recipe.image = `${primaryFood?.storage_path}${primaryFood?.homemade_name?.image_url}`
								// console.log('primaryFoodId', primaryFoodId)
								// console.log('primaryFood', primaryFood)
								// console.log('recipe.image', recipe.image)
							}
						}

						return (
							<IonItem
								className="no-padding-item"
								style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
								key={recipe.id + index}
								button
								detail={false}
								onClick={() =>
									history.push(
										'ricette/' + recipe.recipe_id + '/' + recipe.cycleId
									)
								}>
								<IonThumbnail
									slot="start"
									className="radius">
									<ImageLoader
										image={
											recipe.image
												? recipe.image.includes('http')
													? recipe.image
													: config.env[config.env.mode].url + recipe.image
												: null
										}
										imageType="recipe"
									/>
								</IonThumbnail>
								<div>
									<IonText>
										<div
											dangerouslySetInnerHTML={{
												__html: recipe.name,
											}}
											className="recipe-text capitalize-first-letter"
										/>
									</IonText>
								</div>
							</IonItem>
						)
					})}
				</IonList>
			) : (
				<NoRecords text="ricette" />
			)}
		</div>
	)
}

export default RecipesContent

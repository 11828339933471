import { IonItem, IonList, IonText, IonThumbnail } from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getDietPlan } from '../../../redux/actions'
import dayjs from 'dayjs'
import config from '../../../settings/config'
import { FoodSkeletonItem } from '../../../components/skeletons/FoodSkeletonItem'
import { useHistory } from 'react-router'
import { ImageLoader } from '../../../components/ImageLoader'
import chefHat from '../../../assets/icons/chef-hat.svg'
import { Icon } from '@iconify/react/dist/iconify.js'
import FoodPropertiesModal from '../FoodPropertiesModal'

const lists = [
	'Colazione',
	'Spuntino mattutino',
	'Pranzo',
	'Spuntino pomeridiano',
	'Cena',
]

const MenuContent = ({ date, planData }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const [plan, setPlan] = useState(null)
	const [foods, setFoods] = useState(null)
	const [dayDish, setDayDish] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [showModal, setShowModal] = useState(false)
	const [foodProperties, setFoodProperties] = useState(null)
	const isSmart =
		useSelector((state) => state.user.master_club_subscriber?.is_smart) ?? false

	const processData = (response) => {
		if (!response?.dates?.length) {
			setPlan(null)
			setDayDish(null)
		}
		const date1 = dayjs(date)
		const date2 = dayjs(response.dates[0])
		const dayIndex = date1.diff(date2, 'day')
		const newPlan = response?.dietPlan[dayIndex]
		setPlan(newPlan)
		setFoods(response?.foods)
		const dishKey = 'c1-d' + (dayIndex + 1)
		const dayDishes = response?.dayDishes

		try {
			if (dayDishes) {
				const dishString = response?.dayDishes[dishKey]
				if (dishString !== undefined) {
					setDayDish(dishString)
				} else {
					setDayDish(null)
				}
			}
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		if (planData) {
			processData(planData)
			setIsLoading(false)
			return
		}

		// Fetch data when the component mounts
		dispatch(getDietPlan(new Date(date)))
			.then((response) => {
				response = response.payload
				if (response) {
					processData(response)
				} else {
					// Handle the case where response or its properties are undefined
					console.error('Invalid response data:', response)
				}
			})
			.catch((error) => {
				console.error('Error fetching diet plan:', error)
			})
			.finally(() => {
				setIsLoading(false) // Set loading to false when data fetching is done
			})
	}, [date, isSmart])

	return (
		<div
			style={{ overflowY: 'auto' }}
			className="menu-page">
			{lists.map((list, index) => {
				// if (plan) {
				// 	console.log('index', index)
				// 	console.log('plan', plan)
				// 	console.log('plan[index]', plan[index])
				// 	console.log(
				// 		'plan[index].tables',
				// 		plan[index].tables
				// 	)
				// 	console.log(
				// 		'plan[index].tables.parent',
				// 		plan[index].tables.parent.sort(
				// 			(a, b) => a.sequence - b.sequence
				// 		)
				// 	)
				// }

				return (
					<>
						<b
							className="ion-padding"
							style={{
								display: 'block',
								borderBottom: '1px solid #ddd',
							}}>
							{list}
						</b>
						<IonList key={list + index}>
							{isLoading ? (
								<FoodSkeletonItem />
							) : (
								plan && (
									<>
										{plan[index].tables.parent.map((elementO, index2) => {
											const element = { ...elementO }
											const food = foods?.find(
												(food) => food.id === element.food_id
											)
											if (element.source_type === 'recipe' && !element.image) {
												const primaryFoodId = plan[index].tables.childs[
													element.recipe_id
												]?.find((e) => e.is_primary === true)?.food_id

												const primaryFood = foods?.find(
													(food) => food.id === primaryFoodId
												)

												if (primaryFood) {
													element.image = `${primaryFood?.storage_path}${primaryFood?.homemade_name?.image_url}`
													// console.log('element', element)
													// console.log('primaryFoodId', primaryFoodId)
													// console.log('primaryFood', primaryFood)
													// console.log('element.image', element.image)
												}
											}
											return (
												<IonItem
													detail="false"
													key={element.id}
													button={element.source_type === 'recipe'}
													onClick={() => {
														if (element.source_type === 'recipe') {
															const cycleId = plan[index].id
															history.push(
																`${window.location.pathname}/${list}/${element.recipe_id}/${cycleId}`
															)
														} else {
															setFoodProperties({
																name: food?.homemade_name.name,
																image_url: `${food?.storage_path}${food?.homemade_name?.image_url}`,
																description:
																	food?.homemade_name
																		?.description_nutritional_properties,
															})
															if (
																food?.homemade_name
																	?.description_nutritional_properties
															)
																setShowModal(true)
														}
													}}>
													{element.source_type === 'recipe' ? (
														<IonThumbnail
															className="radius"
															color="medium"
															slot="start">
															{element.type === 'item' ? (
																''
															) : (
																<ImageLoader
																	image={
																		element.image
																			? element.image.includes('http')
																				? element.image
																				: config.env[config.env.mode].url +
																				  element.image
																			: null
																	}
																	imageType="recipe"
																/>
															)}
														</IonThumbnail>
													) : (
														<>
															<IonThumbnail
																className="radius"
																color="medium"
																slot="start">
																<img
																	src={`${food?.storage_path}${food?.homemade_name?.image_url}`}
																	onError={(e) => {
																		e.target.parentElement.style.display =
																			'none'
																	}}
																/>
															</IonThumbnail>
														</>
													)}
													<div className="item-text">
														{element.source_type === 'recipe' ? (
															<p
																style={{ marginTop: '0.5rem' }}
																dangerouslySetInnerHTML={{
																	__html: element.name,
																}}
															/>
														) : (
															<div className="food-container">
																<div>
																	<IonText>
																		<span
																			dangerouslySetInnerHTML={{
																				__html: element.homemade_name,
																			}}
																		/>
																	</IonText>{' '}
																	<IonText color="medium">
																		<span className="capitalize-first-letter">
																			(
																			{element?.best_measure &&
																				element.best_measure
																					.split('(')[0]
																					.trim()}
																			)
																		</span>
																	</IonText>
																	{food?.homemade_name
																		?.description_nutritional_properties && (
																		<Icon
																			icon="ph:info-duotone"
																			style={{
																				color: 'var(--ion-color-primary)',
																				width: '20px',
																				height: '20px',
																				marginBottom: '-5px',
																				marginLeft: '5px',
																			}}
																		/>
																	)}
																</div>
															</div>
														)}
													</div>
												</IonItem>
											)
										})}
									</>
								)
							)}
						</IonList>
					</>
				)
			})}

			{dayDish && !isSmart && (
				<>
					<b
						className="ion-padding"
						style={{
							display: 'block',
							borderBottom: '1px solid #ddd',
						}}>
						Piatto unico del giorno
					</b>
					<IonList style={{ paddingBottom: 0 }}>
						<IonItem
							detail="false"
							button={true}
							onClick={() => {
								history.push(
									`${window.location.pathname}/Piatto unico del giorno/${dayDish.id}`,
									'forward'
								)
							}}>
							<IonThumbnail
								className="radius"
								color="medium"
								slot="start">
								<img
									src={chefHat}
									style={{ borderRadius: 0 }}
								/>
							</IonThumbnail>
							<div className="item-text">
								<p
									style={{ marginTop: '0.5rem' }}
									dangerouslySetInnerHTML={{
										__html: dayDish.name,
									}}
								/>
							</div>
						</IonItem>
					</IonList>
				</>
			)}
			<FoodPropertiesModal
				showModal={showModal}
				setShowModal={setShowModal}
				foodProperties={foodProperties}
			/>
		</div>
	)
}

export default MenuContent
